import { ref, watch, computed, reactive } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import Vue from "vue";
import axios from "@axios";

export default function usedashboardBaraemList() {
  const genderOptions = [{ label: "ذكر" }, { label: "أنثى" }];
  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id", label: " الرقم", sortable: true },
    { key: "first_name", label: "الاسم", sortable: true },
    { key: "last_name", label: "النسبة", sortable: true },
    { key: "username", label: "اسم المستخدم", sortable: true },
    { key: "hire_date", label: "تاريخ التوظيف", sortable: true },

    { key: "role.name", label: "الدور", sortable: true },
    {
      key: "main_service.name",
      label: "القسم",
      formatter: title,
      sortable: true,
    },

    { key: "actions", label: "الخيارات" },
  ];
  const perPage = ref(10);
  const series = ref([20, 100]);
  const chartOptions = reactive({
    chart: {
      height: 350,
      type: "bar",
    },
    series: [{ data: [] }],
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    labels: ["  الهدف  ", "  الانجاز  "],
  });
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const employeFilter = ref('');
  const statusFilter = ref(null);
  const date = ref(null);
  const durationType = ref(null);
  const goalFilter = ref(null);

  const roleOptions = ref([]);
  const employeOptions = ref([]);
  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });
  const role = JSON.parse(localStorage.getItem("userData"));
  const refetchData = () => {
    refUserListTable.value.refresh();
  };
  if (
    role.roles.some( el => el['name'] === 'information_manager' )
   ||  role.roles.some( el => el['name'] === 'HR' )
   
  ) {
    store.dispatch("app-dashboard/getRoles").then((res) => {
      roleOptions.value = res.data.roles;
    });
    store.dispatch("app-dashboard/getUsers").then((res) => {
      employeOptions.value = [];
      res.data.forEach((el) => {
        employeOptions.value.push({
          value: el.id,
          label: `${el.first_name} ${el.last_name} `,
        });
      });
      // this.employeOptions.value.push({ value: el.id, label: `${el.first_name} ${el.last_name} ` })

      //  employeOptions.value=res.data;
    });
  }
 
  const getServiceType = () => {};
  const fetchData = (ctx, callback) => {
    store
      .dispatch("app-dashboard/fetchData", {
        goal_type: goalFilter.value,
        date: date.value,
        is_summary: true,
        duration_type: durationType.value,
        employee_id:employeFilter.value
      })
      .then((response) => {
        window.dispatchEvent(new Event("resize"));
        //console.log("stat",response)
        //console.log("stat",response.data.total_goal)
        let data = [];

        if (response.data.total_goal) {
          data.push(parseInt(response.data.total_goal));
          data.push(parseInt(response.data.total_inserted_families));
          chartOptions.series[0].data = data;
        }

        //console.log(data)

        //console.log( chartOptions.series)
      })
      .catch(() => {
        Vue.swal({
          title: "حدثت مشكلة في استرجاع البيانات",
          icon: "error",

          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
  };
  // fetchData()
  watch(
    [goalFilter, date, durationType, roleFilter, employeFilter, statusFilter],
    () => {
      fetchData();
    }
  );
  return {
    role,
    series,
    chartOptions,
    roleOptions,
    employeOptions,
    fetchData,
    genderOptions,
    getServiceType,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    refetchData,
    durationType,
    date,
    goalFilter,
    // Extra Filters
    roleFilter,
    employeFilter,
    statusFilter,
  };
}
