<template>

  <div>
    <!-- Filters -->
    <dashboard-baraem-filters
      :employe-filter.sync="employeFilter"
      :goal-filter.sync="goalFilter"
      :date-filter.sync="date"
      :duration-filter.sync="durationType"
      :role-filter.sync="roleFilter"
      :employe-options="employeOptions"
      :goal-options="goalOptions"
      :role-options="roleOptions"
      :duration-options="durationOptions"
    />

    <b-row
      v-if="role !='information_manager' && role !='HR'"
    >
      <b-col
        md="6"
      ><b-card
        no-body
        class="mb-0"
      >
        <b-card-header> انجازي</b-card-header>
        <b-card-body>
     
          <!-- apex chart -->
          <vue-apex-charts
            height="400"
            :options="chartOptionsEmploye"
            :series="chartOptionsEmploye.series"
          />

          <!-- chart info -->
        </b-card-body>
      </b-card></b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-row
      v-if="role =='information_manager' || role =='HR'"
    >
      <b-col
        md="6"
      ><b-card
        no-body
        class="mb-0"
      >
        <b-card-header>قسم التسجيل</b-card-header>
        <b-card-body>
          <!-- apex chart -->

          <vue-apex-charts
            height="400"
            :options="chartOptions"
            :series="chartOptions.series"
          />

          <!-- chart info -->
        </b-card-body>
      </b-card></b-col>
      <b-col md="6">
        <b-card
          no-body
          class="mb-2"
        >
          <b-card-header> مدير الحالة</b-card-header>
          <b-card-body>
        
            <!-- apex chart -->
            <vue-apex-charts
              height="400"
              :options="chartOptionsCaseManger"
              :series="chartOptionsCaseManger.series"
            />

            <!-- chart info -->
          </b-card-body>
        </b-card></b-col>

      <b-col md="6">
        <b-card
          no-body
          class="mb-0 mt-10"
        >
          <b-card-header> فريق جوال</b-card-header>
          <b-card-body>
         
       
            <!-- apex chart -->
            <vue-apex-charts
              height="400"
              :options="chartOptionsMobileTeam"
              :series="chartOptionsMobileTeam.series"
            />

            <!-- chart info -->
          </b-card-body>
        </b-card></b-col>
    </b-row>
    <b-row>

      <b-card>

        <b-card-header> التعميمات</b-card-header>
        <b-card-body>
          <infrom />
        </b-card-body>
      </b-card>
    </b-row>
  </div>

</template>

<script>
import {
  BCard,
  BRow,
  BFormGroup,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BPagination,
  BCardHeader,
  BCardBody,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import VueApexCharts from 'vue-apexcharts'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'

import dashboardBaraemFilters from './dashboardBaraemFilters.vue'
import usedashboardBaraemList from './usedashboardBaraemList'
import infrom from './infrom.vue'
import DashboardStoreModule from './DashboardStoreModule'

export default {
  setup() {
    const DASHBOARD_APP_STORE_MODULE_NAME = 'app-dashboard'

    // Register module
    if (!store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        DASHBOARD_APP_STORE_MODULE_NAME,
        DashboardStoreModule,
      )
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME)) { store.unregisterModule(DASHBOARD_APP_STORE_MODULE_NAME) }
    })

    const goalOptions = [
      { value: 0, label: 'قسم التسجيل ' },
      { value: 1, label: ' مدراء الحالة' },
      { value: 2, label: ' الفريق الجوال' },
    ]

    const durationOptions = [
      { value: 0, label: ' يومي' },
      { value: 1, label: '  شهري' },
      { value: 2, label: '  سنوي' },
    ]
    const {
      roleOptions,
      fetchBenServices,
      employeOptions,
      tableColumns,

      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refServiceListTable,
      refetchData,

      // durationType,
      // date,

      // goalFilter,
      // employeFilter,
      // roleFilter,
      openStatus,
      changeStatus,
      // chartOptions,

      succesStory,
      series,
    } = usedashboardBaraemList()

    return {
      // Sidebar
      series,
      durationOptions,
      employeOptions,
      // chartOptions,
      fetchBenServices,
      changeStatus,

      openStatus,
      succesStory,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refServiceListTable,
      refetchData,

      // Filter
      // durationType,
      // date,
      // goalFilter,
      // employeFilter,
      // roleFilter,

      goalOptions,
      roleOptions,
    }
  },
  components: {
    VueApexCharts,
    dashboardBaraemFilters,

    BButton,

    BDropdownItemButton,
    BFormInvalidFeedback,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
    infrom,
    vSelect,
  },
  data() {
    return {
      label: [],
      goalFilter: null,
      role: '',
      date: '',
      durationType: "",
      roleFilter: null,
      employeFilter: "",
      chartOptionsEmploye: {
        series: [],
        chart: {
          type: 'pie',
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#17a2b8', '#FFA1A1'],

        labels: ['  الهدف  ', '  الانجاز  '],

        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,

                total: {
                  show: false,
                  offsetY: 15,
                },
              },
            },
          },
        },
      },
      test:'',
      chartOptions: {
        series: [],
        chart: {
          type: 'pie',
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#17a2b8', '#FFA1A1'],

        labels: ['  الهدف  ', '  الانجاز  '],

        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,

                total: {
                  show: false,
                  offsetY: 15,
                },
              },
            },
          },
        },
      },
      chartOptionsCaseManger: {
        series: [],
        chart: {
          type: 'pie',
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#17a2b8', '#FFA1A1'],

        labels: ['  الهدف  ', '  الانجاز  '],

        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,

                total: {
                  show: false,
                  offsetY: 15,
                },
              },
            },
          },
        },
      },
      chartOptionsMobileTeam: {
        series: [],
        chart: {
          type: 'pie',
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#17a2b8', '#FFA1A1'],

        labels: ['  الهدف  ', '  الانجاز  '],

        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,

                total: {
                  show: false,
                  offsetY: 15,
                },
              },
            },
          },
        },
      },
    }
  },
  watch: {
    date() {
      this.getDataMobileTeam()
      this.getData()
      this.getDataCaseManger()
      this.getDataEmploye()
    },
    durationType() {
      this.getDataMobileTeam()
      this.getData()
      this.getDataCaseManger()
      this.getDataEmploye()
    },
    employeFilter() {
      this.getDataMobileTeam()
      this.getData()
      this.getDataCaseManger()
      this.getDataEmploye()
    },
  },
  created() {
    let today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
    const yyyy = today.getFullYear()

    today = `${yyyy}-${mm}-${dd}`

    this.date = today
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.role = userData.roles[0].name
    // if(userData.has_changed == 0) this.$router.push('/apps/users/change-password')
  //  console.log(this.role)
    if (this.role == 'information_manager' || this.role == 'HR') {
      this.getData()
      this.getDataCaseManger()
      this.getDataMobileTeam()
    } else {
     this.getDataEmploye()
    }
  },
  methods: {
    getData() {
      console.log(this.durationType)
      window.dispatchEvent(new Event('resize'))
      this.$http
        .get(
          `/api/v1/achievement_target_stats?goal_type=0&date=${this.date}&is_summary=0&duration_type=${this.durationType != null ?this.durationType : ""}&employee_id=${this.employeFilter != null?this.employeFilter : ""}
      `,
        )
        .then(response => {
          window.dispatchEvent(new Event('resize'))

      
          let data = []
// console.log("goal_type = 0 , getData" )
//console.log(response.data)
          // if (response.data.inserted_families_stats) {
            this.test=response.data.inserted_families_stats.goal
          data.push(parseInt(response.data.inserted_families_stats.goal))
          data.push(parseInt(response.data.inserted_families_stats.progress))
          this.chartOptions.series = data
          window.dispatchEvent(new Event('resize'))
          // }
        })
    },
    getDataCaseManger() {
      window.dispatchEvent(new Event('resize'))
      this.$http
        .get(
          `/api/v1/achievement_target_stats?goal_type=1&date=${this.date}&is_summary=0&duration_type=${this.durationType != null?this.durationType : ""}&employee_id=${this.employeFilter != null?this.employeFilter : ""}
      `,
        )
        .then(response => {
          // window.dispatchEvent(new Event('resize'))
          // console.log("goal_type = 1 , getDataCaseManger" )
// console.log(response.data)
          const data = []

          // if (response.data.total_goal) {
          //   data.push(parseInt(response.data.total_goal));
          //   data.push(parseInt(response.data.total_inserted_families));
          //   this.chartOptionsCaseManger.series = data;
          //   window.dispatchEvent(new Event("resize"));
          // }
        
          if (response.data) {
            data.push(parseInt(response.data.handled_cases_stats.goal))
            data.push(parseInt(response.data.handled_cases_stats.progress))
            this.chartOptionsCaseManger.series = data
            // console.log("chartOptionsCaseManger.series")
            // console.log(this.chartOptionsCaseManger.series)
            window.dispatchEvent(new Event('resize'))
          }
        })
    },
    getDataMobileTeam() {
      window.dispatchEvent(new Event('resize'))
      this.$http
        .get(
          `/api/v1/achievement_target_stats?goal_type=2&date=${this.date}&is_summary=0&duration_type=${this.durationType != null?this.durationType : ""}&employee_id=${this.employeFilter != null?this.employeFilter : ""}
      `,
        )
        .then(response => {
          window.dispatchEvent(new Event('resize'))
          // console.log("goal_type = 2 , getDataMobileTeam" )
// console.log(response.data)
          const data = []

          if (response.data) {
            data.push(parseInt(response.data.mobile_team_stats.goal))
            data.push(parseInt(response.data.mobile_team_stats.progress))
            this.chartOptionsMobileTeam.series = data
            window.dispatchEvent(new Event('resize'))
          }
        })
    },
    getDataEmploye() {
      window.dispatchEvent(new Event('resize'))
      this.$http
        .get(
          `/api/v1/achievement_target_stats?date=${this.date}&is_summary=0&duration_type=${this.durationType != null?this.durationType : ""}&employee_id=${this.employeFilter != null?this.employeFilter : ""}
      `,
        )
        .then(response => {
          window.dispatchEvent(new Event('resize'))
          // console.log("no goal_type , employee filter, getDataEmploye" )
// console.log(response.data)
          const data = []

          if (response.data) {
            data.push(parseInt(response.data.goal))
            data.push(parseInt(response.data.progress))
            this.chartOptionsEmploye.series = data
            window.dispatchEvent(new Event('resize'))
          }
        })
    },
  },

}
</script>

<style lang="scss">
div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.card {
    width: 100%;
    margin: 14px;
}
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
