<template>
  <b-card no-body>
    <b-card-header class="pb-50" />
    <b-card-body>

      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>المدة</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="durationFilter"
            :options="durationOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:durationFilter', val)"
          />
        </b-col>

        <b-col
          md="3"
          xl="4"
        >

          <b-form-group
            label-for="date"
          >
            <label>تاريخ </label>
            <flat-pickr
              v-model="dateFilter"
              class="form-control"
              placeholder="حدد تاريخ "
              @input="(val) => $emit('update:dateFilter', val)"
            />
          </b-form-group>

        </b-col>
        <b-col
          v-if="role =='information_manager' || role =='HR'"
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>الدور</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="roleFilter"
            :options="roleOptions"
            label="name"
            class="w-100"
            :reduce="val => val.id"
            @input="(val) => $emit('update:roleFilter', val)"
          />
        </b-col>
        <b-col
          v-if="role =='information_manager' || role =='HR' "
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >

          <label>الموظف</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="employeFilter"
            :options="employeOptions"
            :reduce="val => val.value"
            class="w-100"

            @input="(val) => $emit('update:employeFilter', val)"
          />
        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    BRow,
    flatPickr,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormGroup,
  },
  props: {
    roleFilter: {
      type: [Number, null],
      default: null,
    },
    employeFilter: {
      type: [Number, null],
      default: null,
    },
    dateFilter: {
      type: [String, null],
      default: null,
    },
    durationFilter: {
      type: [Number, null],
      default: null,
    },
    goalFilter: {
      type: [Number, null],
      default: null,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    durationOptions: {
      type: Array,
      required: true,
    },
    employeOptions: {
      type: Array,
      required: true,
    },
    goalOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      role: '',
    }
  },

  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.role = userData.roles[0].name
    // console.log('userData',userData)
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
