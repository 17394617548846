<template>
  <div>
    <!-- Table  :caption-html="
              `${item.title} 
              <hr>
              تاريخ البداية ${item.start_date}
              <br/>
              تاريخ النهاية ${item.end_date}`"
              class="card-img-overlay"
              :text="item.body"Container Card -->
  
      <div class="app-action">
        <b-row>
          <b-col
            cols="6"
            md="6"
            class="
                d-flex
  
                mb-1 mb-md-0
              "
          >
         
            <!-- <label>SortBy</label>
                <v-select
                style="width:200px"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sortOptions"
                  v-model="sort"
                  multiple
                  :clearable="true"
                   />
             -->
          </b-col>
        </b-row>
      </div>

      <!-- Update Mail Folder Dropdown -->
      <b-row>
<b-col v-for="item in informs" cols="4">
  <router-link :to="`/apps/show-inform/${item.id}`">
      <b-card no-body class="mb-0 mt-10">
            <b-card-header>{{ item.title }} </b-card-header>
            <b-card-body>
              <!-- apex chart -->
              
<b-img
width="200"
hieght="500"
:src="item.image"
/>
<p class="m-10">
  {{ item.body }}
</p>
              <!-- chart info -->
            </b-card-body>
          </b-card>
        </router-link>
        </b-col>
   
      </b-row>
      <div class="mx-2 mb-2" v-if="informs.length > 0">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing 3  of
              {{ totalUsers }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
  
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BImg,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BPagination,
  BCardHeader,
  BFormTextarea,
  BCardBody,
  BFormInvalidFeedback,
  BCarousel, BCarouselSlide, BCardText,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { ref, onUnmounted } from '@vue/composition-api'

import { max } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import infrom from './inform'
import DashboardStoreModule from './DashboardStoreModule'
import store from '@/store'

export default {
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-dashboard'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) { store.registerModule(USER_APP_STORE_MODULE_NAME, DashboardStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) { store.unregisterModule(USER_APP_STORE_MODULE_NAME) }
    })

    const serviceId = localStorage.getItem('roleService')

    const role = JSON.parse(localStorage.getItem('userData'))

    const {
      fetchListInform,
      tableColumns,
      responseByEmployee,

      perPage,
      currentPage,
      totalUsers,

      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      reftransferListTable,
      refetchData,

      TransferType,
      informs,
    } = infrom()

    return {
      informs,
      role,
      responseByEmployee,

      fetchListInform,
      tableColumns,

      serviceId,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      reftransferListTable,
      refetchData,

      TransferType,

    }
  },
  components: {
    BCarousel,
    BCarouselSlide,
    BImg,
    BCardText,

    BFormTextarea,
    BButton,
    max,
    BDropdownItemButton,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,

    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
    vSelect,

  },

}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>
  <style scoped>
  div {
    color: #7e7a9f;
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .media {
    color: #7e7a9f;
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .carousel-caption h3{
    color: #000 !important;
    font-size: 20px;
  }
  </style>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
